import React, { useState, useEffect } from 'react';
import { Box, Card, FormControl, TextField, Typography, Dialog, DialogContent, ImageList, ImageListItem, ImageListItemBar, Link, useMediaQuery } from '@mui/material';
import { Close } from '@mui/icons-material';
import { PublicListMemo, ListMemo } from '../services/MemoService';
import { usersList } from '../../client/services/UserService';
import { formatDate, trClose } from '../../client/common/Common';
import { useTheme } from '@mui/material/styles';

const RandomRange = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

const getInitials = (name) => {
  if (typeof name !== 'string') { return '' }
  const names = name.split(' ');
  const initials = names.map(n => n[0]).join('');
  return initials.toUpperCase();
};

const getColorFromString = (str) => {
  if (typeof str !== 'string') { return '#000000' }
  let hash = 0;
  for (let i = 0; i < str.length; i++) { hash = str.charCodeAt(i) + ((hash << 5) - hash) }
  const color = `#${((hash >> 24) & 0xFF).toString(16)}${((hash >> 16) & 0xFF).toString(16)}${((hash >> 8) & 0xFF).toString(16)}${(hash & 0xFF).toString(16)}`;
  return color;
};

const AvImg = (name, width) => (<Box sx={{ bgcolor: getColorFromString(name), width: `${width}%`, height: `${RandomRange(200, 300)}px`, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
  <Typography sx={{ textAlign: 'center', fontSize: '3rem', color: '#fff' }}>
    {getInitials(name)}
  </Typography>
</Box>);

export default function MemoWall() {
  const userId = sessionStorage.getItem('userId') || null;
  const [dcd, setdcd] = useState('');
  const [memModal, setmemModal] = useState(null);
  const [memList, setmemList] = useState([]);
  const [uList, setuList] = useState([]);
  const [search, setsearch] = useState('');
  const searchTLC = search?.toLowerCase();
  const memListF = memList?.filter((m) => (m.name?.toLowerCase().includes(searchTLC) || formatDate(m.birth_date)?.slice(-4)?.includes(searchTLC) || formatDate(m.death_date)?.slice(-4)?.includes(searchTLC)));
  const uName = (ID) => (uList?.find((u) => u._id === ID)?.full_name);
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));


  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp1 = userId ? await ListMemo() : await PublicListMemo()
        if (resp1) { setmemList(resp1.result.filter(m => m.archive === false && m.memopost_status === 'Published').reverse()) }
        const resp2 = await usersList();
        if (resp2) { setuList(resp2.result.filter(u => u.status === 'Active')) }
      }
      catch (error) { console.log(error) }
    }; fetchData();
  }, [])

  return (<Card sx={{ minHeight: '75vh' }}>
    {memList.length > 0 &&
      <Box sx={{ display: 'flex', gap: 2, flexDirection: isMobile ? 'column' : 'row', alignItems: isMobile ? 'stretch' : null }}>
        <Typography align="left" sx={{ flex: 1 }}>
          If you wish to post message/photo in memory of a person whom you lost by suicide it can be displayed here for a specific period. Please send the details to <Link style={{ fontWeight: 'bold', fontSize: '18px' }} href="mailto:sas@snehaindia.org" >sas@snehaindia.org.</Link>
        </Typography> <FormControl sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', mb: 2 }}><TextField value={search}
          size='small' sx={{ width: '150px' }} onChange={(e) => setsearch(e.target.value.toLowerCase())} label='Search' /></FormControl>
      </Box >
    }

    {memListF.length > 0 ? <ImageList variant="masonry" cols={4} gap={8}>{memListF?.map((m, i) => (
      <ImageListItem key={i} sx={{ border: '1px solid gray', '&:hover': { cursor: 'pointer', border: '1px solid #b5651d' } }} onClick={() => { setmemModal(m); setdcd(decodeURIComponent(atob(m?.message))); }}>
        {m.image ? <img src={`data:image/png;base64,${m.image}`} alt={m.name} />
          : AvImg(m.name, 100)}
        <ImageListItemBar title={m.name} subtitle={<>{formatDate(m.birth_date)?.slice(-4)} - {formatDate(m.death_date)?.slice(-4)}</>} />
      </ImageListItem>
    ))}</ImageList> : <>{search ? <Typography variant='h6' sx={{ textAlign: 'center' }}></Typography> : <Typography align="left" sx={{ flex: 1 }}>
      If you wish to post message/photo in memory of a person whom you lost by suicide it can be displayed here for a specific period. Please send the details to <Link style={{ fontWeight: 'bold', fontSize: '18px' }} href="mailto:sas@snehaindia.org" >sas@snehaindia.org.</Link>
    </Typography>}</>

    }

    <Dialog fullWidth open={memModal} onClose={() => { setmemModal(null); setdcd(''); }} sx={{ overflow: 'auto' }}>
      <Close sx={trClose} onClick={() => { setmemModal(null); setdcd(''); }} />
      <DialogContent><Card sx={{ flexDirection: 'row', gap: '16px', maxHeight: '80vh', overflow: 'auto' }}>
        {memModal?.image ? <img src={`data:image/png;base64,${memModal?.image}`} alt={memModal?.name} style={{ width: '40%', objectFit: 'scale-down' }} />
          : AvImg(memModal?.name, 40)}
        <div style={{ width: '60%', display: 'flex', flexDirection: 'column' }}>
          <Typography variant='h6' sx={{ mb: 0, color: '#000' }}>{memModal?.name}</Typography>
          <Typography sx={{ mb: 4, fontWeight: 'bold' }}>{formatDate(memModal?.birth_date)?.slice(-4)} - {formatDate(memModal?.death_date)?.slice(-4)}</Typography>
          {dcd ? <div>
            <Typography sx={{ color: 'GrayText', mb: 0 }} dangerouslySetInnerHTML={{ __html: dcd }} />
            <Typography>- {uName(memModal?.memopost_createdby)}</Typography>
          </div> : <Typography>Posted by <b>{uName(memModal?.memopost_createdby)}</b></Typography>}
        </div>
      </Card></DialogContent>
    </Dialog>
  </Card>)
}