import React, { useState, useEffect } from 'react';
import {
    Fab, TablePagination, Card, Grid, Typography, Divider, ListSubheader, Button, TextField,
    MenuItem, FormControl, FormHelperText, Select, Checkbox, ListItemText, Toolbar, Tab, Tabs,
    useMediaQuery, Paper, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, IconButton,
    TableFooter, Container, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import { Add, Close, Info, Edit, Cancel, EditNote } from '@mui/icons-material';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { tabThemeAdmin, StyledAlert } from '../TabsCore';
import { ListMtg, AddMtg, EdtMtg, ArchMtg } from '../../services/MeetingService';
import { userList } from '../../services/UserService';
import { regList } from '../../services/RegistrationService';
import { adminTH, TPA, FormatDT, pBtn, nBtn, PerChk, IsoToDate, IsoToTime } from '../../common/Common';

const meetObj = {
    _id: '',
    title: '',
    type: '',
    date_time: '',
    description: '',
    link: '',
    venue: '',
    total_participants: [],
    meeting_createdby: '',
    attended_participants: [],
    summary: '',
    report_status: '',
    report_createdby: ''
}

export default function MeetingManagement() {
    const ITEM_HEIGHT = 50;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = { PaperProps: { style: { maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP } } };
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
    const userId = sessionStorage.getItem('userId');
    const [now, setnow] = useState(new Date());
    const [isPast, setisPast] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [ti, setti] = useState(!PerChk('FUTURE_MEETINGS') ? '0' : !PerChk('CANCELLED_MEETINGS') ? '1' : '2')
    const [addModal, setaddModal] = useState(false);
    const [rptModal, setrptModal] = useState(false);
    const [archModal, setarchModal] = useState(null);
    const [addAlert, setaddAlert] = useState(null);
    const [editAlert, seteditAlert] = useState(null);
    const [archAlert, setarchAlert] = useState(null);
    const [pastMeets, setpastMeets] = useState([]);
    const [upcomingMeets, setupcomingMeets] = useState([]);
    const [archMeets, setarchMeets] = useState([]);
    const [uList, setuList] = useState([]);
    const [nList, setnList] = useState([]);
    const [rptEdit, setrptEdit] = useState(false);
    const [rptObj, setrptObj] = useState(meetObj);
    const [editDateTime, setEditDateTime] = useState(false)
    const [dateTemp, setdateTemp] = useState(now.toLocaleDateString('en-CA'));
    const [timeTemp, settimeTemp] = useState(now.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', hour12: false }));
    let addDisabled = isLoading || !rptObj?.title || !rptObj?.type || (!rptObj?.link && rptObj?.type === 'Online') || (!rptObj?.venue && rptObj?.type === 'Face-to-Face') || !dateTemp || !timeTemp || rptObj?.total_participants.length < 2 || !rptObj?.description
    //let addRptDisabled = isLoading || !rptObj?.title || !rptObj?.type || (!rptObj?.link && rptObj?.type === 'Online') || (!rptObj?.venue && rptObj?.type === 'Face-to-Face') || !dateTemp || !timeTemp || rptObj?.total_participants.length < 2 || !rptObj?.description || !rptObj.summary
    let editDisabled = isLoading
    let editRptDisabled = isLoading || rptObj?.total_participants.length < 2 || !rptObj?.summary
    const [page, setPage] = useState(0);
    const [searchFilter, setsearchFilter] = useState('');
    const getUserFullNameById = (id) => { const user = nList.find((user) => user._id === id); return user && user.full_name };
    const checkInactive = (id) => { const user = nList.find((user) => user._id === id); return user.status === 'Active' ? false : true };
    const sfTLC = searchFilter?.toLowerCase();
    const pastF = pastMeets.filter((r) => {
        return (
            r.title?.toLowerCase().includes(sfTLC) ||
            FormatDT(r.date_time)?.toLowerCase().includes(sfTLC) ||
            r.type?.toLowerCase().includes(sfTLC) ||
            r.link?.toLowerCase().includes(sfTLC) ||
            r.venue?.toLowerCase().includes(sfTLC) ||
            r.total_participants.length.toString().includes(sfTLC) ||
            r.description?.toLowerCase().includes(sfTLC) ||
            r.summary?.toLowerCase().includes(sfTLC)
        )
    }); const pastP = pastF.slice(page * 10, page * 10 + 10);
    const upcomingF = upcomingMeets.filter((r) => {
        return (
            r.title?.toLowerCase().includes(sfTLC) ||
            FormatDT(r.date_time)?.toLowerCase().includes(sfTLC) ||
            r.type?.toLowerCase().includes(sfTLC) ||
            r.link?.toLowerCase().includes(sfTLC) ||
            r.venue?.toLowerCase().includes(sfTLC) ||
            r.total_participants.length.toString().includes(sfTLC) ||
            r.description?.toLowerCase().includes(sfTLC)
        )
    }); const upcomingP = upcomingF.slice(page * 10, page * 10 + 10);
    const archF = archMeets.filter((r) => {
        return (
            r.title?.toLowerCase().includes(sfTLC) ||
            FormatDT(r.date_time)?.toLowerCase().includes(sfTLC) ||
            r.type?.toLowerCase().includes(sfTLC) ||
            r.link?.toLowerCase().includes(sfTLC) ||
            r.venue?.toLowerCase().includes(sfTLC) ||
            r.total_participants.length.toString().includes(sfTLC) ||
            r.description?.toLowerCase().includes(sfTLC)
        )
    }); const archP = archF.slice(page * 10, page * 10 + 10);

    useEffect(() => { fetchMeets() }, [])

    useEffect(() => {
        if (dateTemp && timeTemp) {
            let localInFormat = new Date(`${dateTemp}T${timeTemp}:00`);
            const locale = new Date(`${dateTemp}T${timeTemp}:00.000Z`);
            const utcOffset = locale.getTimezoneOffset() * 60 * 1000;
            const utc = new Date(locale.getTime() + utcOffset).toISOString();
            setrptObj({ ...rptObj, date_time: utc });
            setisPast(localInFormat < now.setSeconds(0, 0));
        }
    }, [dateTemp, timeTemp])

    const fetchMeets = async () => {
        try {
            const resp1 = await ListMtg(); if (resp1) {
                setpastMeets(resp1.result.filter(meet => meet.cancelled === false).filter(meet => new Date(meet.date_time) < now));
                setupcomingMeets(resp1.result.filter(meet => meet.cancelled === false).filter(meet => new Date(meet.date_time) >= now));
                setarchMeets(resp1.result.filter(meet => meet.cancelled === true));
            }
            const resp2 = await regList();
            const resp3 = await userList();
            let activeUsers = resp3.result.filter(user => user.status !== 'Pending');
            setnList(activeUsers);
            setuList(resp2.result.filter(item => activeUsers.some(user => user._id === item.user_id)));
        } catch (error) { console.log(error) }
    }

    const AddReport = async () => {
        setIsLoading(true);
        try {
            let addObj = {
                title: rptObj.title.trim(),
                type: rptObj.type,
                date_time: rptObj.date_time || now.toISOString(),
                description: rptObj.description && rptObj.description.trim(),
                link: rptObj.link,
                venue: rptObj.venue.trim(),
                total_participants: rptObj.total_participants,
                meeting_createdby: userId,
                /* attended_participants: isPast ? rptObj.attended_participants : [],
                summary: isPast ? rptObj.summary : '',
                report_status: isPast ? 'Published' : '',
                report_createdby: isPast ? userId : '' */
            }
            const resp = await AddMtg(addObj); if (resp) {
                fetchMeets(); setaddModal(false);
                setaddAlert('success');
                setTimeout(() => { setaddAlert(null) }, 3000);
            }
        }
        catch (error) {
            console.log('Error--', error); setaddAlert('error');
            setTimeout(() => { setaddAlert(null) }, 3000);
        }
        setIsLoading(false); setrptObj(meetObj);
    }

    const EditReport = async (id) => {
        setIsLoading(true);
        try {
            let editObj = {
                date_time: rptObj.date_time || now.toISOString(),
                total_participants: rptObj.total_participants,
                attended_participants: rptObj.attended_participants,
                summary: rptObj.summary && rptObj.summary.trim(),
                report_status: 'Published',
                report_createdby: userId
            }
            const resp = await EdtMtg(id, editObj); if (resp) {
                fetchMeets(); setrptModal(false); seteditAlert('success')
                setTimeout(() => { seteditAlert(null) }, 3000);
            }
        }
        catch (error) {
            console.log('Error--', error); seteditAlert('error');
            setTimeout(() => { seteditAlert(null) }, 3000);
        }
        setIsLoading(false); setrptObj(meetObj);
    }

    const ArchReport = async (id) => {
        setIsLoading(true);
        try {
            const resp = await ArchMtg(id); if (resp) {
                setrptModal(false); setrptEdit(false); setrptObj(meetObj);
                fetchMeets(); setarchAlert('success'); setarchModal(null);
                setTimeout(() => { setarchAlert(null) }, 3000);
            }
        } catch (error) {
            console.log('Error--', error); setarchAlert('error');
            setTimeout(() => { setarchAlert(null) }, 3000);
        }
        setIsLoading(false);
    }

    return (<>
        <Fab hidden={PerChk('ADD_MEETING')} title='Create a meeting' onClick={() => { setnow(new Date()); setaddModal(true); }}
            sx={{ position: 'fixed', bottom: 40, right: 20 }}><Add sx={{ color: '#fff' }} /></Fab>

        {addAlert && <StyledAlert severity={addAlert}>{addAlert === 'success' ? 'Meeting added' : 'Failed to add meeting'}</StyledAlert>}
        {editAlert && <StyledAlert severity={editAlert}>{editAlert === 'success' ? 'Meeting updated' : 'Failed to update Meeting'}</StyledAlert>}
        {archAlert && <StyledAlert severity={archAlert}>{archAlert === 'success' ? 'Meeting cancelled' : 'Failed to cancel meeting'}</StyledAlert>}

        <Toolbar><ThemeProvider theme={tabThemeAdmin}><Tabs value={ti} onChange={(e, v) => { setti(v); setsearchFilter(''); setPage(0); }}>
            <Tab value={'0'} label='Upcoming' hidden={PerChk('FUTURE_MEETINGS')} />
            <Tab value={'1'} label='Past' hidden={PerChk('CANCELLED_MEETINGS')} />
            <Tab value={'2'} label='Cancelled' hidden={PerChk('PAST_MEETINGS')} />
        </Tabs></ThemeProvider></Toolbar>

        <Container><Grid container>
            {((ti === '0' && upcomingMeets.length > 0) || (ti === '1' && pastMeets.length > 0) || (ti === '2' && archMeets.length > 0)) &&
                <Grid item xs={12}
                    sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', mr: 2, mb: 1 }}>
                    <FormControl sx={{ width: '125px' }}><TextField label='Search' value={searchFilter}
                        size='small' onChange={(e) => setsearchFilter(e.target.value)} /></FormControl>
                </Grid>
            }

            <Grid item xs={12}>{
                (ti === '0' && upcomingF.length > 0) ?
                    <Paper sx={{ width: '100%', overflow: 'hidden' }} hidden={PerChk('FUTURE_MEETINGS')}><TableContainer sx={{ maxHeight: 440 }}><Table>
                        <TableHead sx={adminTH}><TableRow>
                            <TableCell sx={{ color: '#fff' }}>Title</TableCell>
                            <TableCell sx={{ color: '#fff' }}>Date & Time</TableCell>
                            <TableCell sx={{ color: '#fff' }}>Type</TableCell>
                            <TableCell sx={{ color: '#fff' }}>Link/Venue</TableCell>
                            <TableCell sx={{ color: '#fff' }}>Description</TableCell>
                            <TableCell />
                        </TableRow></TableHead>

                        <TableBody>{upcomingP.sort((a, b) => new Date(b.date_time) - new Date(a.date_time)).map((r, i) => (<TableRow key={i} onClick={() => { setrptObj(r); setrptModal(true); }}
                            sx={{ '&:hover': { cursor: 'pointer', bgcolor: '#ddd' } }}>
                            <TableCell><Typography>{r.title}</Typography></TableCell>
                            <TableCell><Typography style={{ width: "maxContent" }}>{FormatDT(r.date_time)}</Typography></TableCell>
                            <TableCell><Typography>{r.type}</Typography></TableCell>
                            <TableCell><Typography>{r.link || r.venue}</Typography></TableCell>
                            <TableCell><Typography>{r.description}</Typography></TableCell>
                            <TableCell onClick={(e) => e.stopPropagation()} align='right'>
                                <IconButton hidden={PerChk('EDIT_MEETING')} title='Edit meeting' onClick={() => {
                                    setrptObj(r);
                                    ; setrptEdit(true); setrptModal(true); setEditDateTime(true);
                                }}><Edit sx={{ color: '#b5651d' }} /></IconButton>
                                <IconButton hidden={PerChk('CANCEL_MEETING')} title='Cancel meeting' onClick={() => setarchModal(r._id)}><Cancel sx={{ color: '#c00' }} /></IconButton>
                            </TableCell>
                        </TableRow>))}</TableBody>

                        <TableFooter><TableRow><TablePagination count={upcomingF.length}
                            page={page} rowsPerPage={10}
                            sx={{ '.MuiTablePagination-displayedRows': { display: 'none' } }}
                            onPageChange={(e, p) => { setPage(p) }} rowsPerPageOptions={[]}
                            ActionsComponent={TPA} /></TableRow></TableFooter>
                    </Table></TableContainer></Paper>
                    : (ti === '1' && pastF.length > 0) ?
                        <Paper sx={{ width: '100%', overflow: 'hidden' }} hidden={PerChk('CANCELLED_MEETINGS')}><TableContainer sx={{ maxHeight: 440 }}><Table>
                            <TableHead sx={adminTH}><TableRow>
                                <TableCell sx={{ color: '#fff' }}>Title</TableCell>
                                <TableCell sx={{ color: '#fff' }}>Date & Time</TableCell>
                                <TableCell sx={{ color: '#fff' }}>Type</TableCell>
                                <TableCell sx={{ color: '#fff' }}>Link/Venue</TableCell>
                                <TableCell sx={{ color: '#fff' }}>Description</TableCell>
                                <TableCell />
                            </TableRow></TableHead>

                            <TableBody>{pastP.sort((a, b) => new Date(b.date_time) - new Date(a.date_time)).map((r, i) => (<TableRow key={i} onClick={() => { setrptObj(r); setrptModal(true); }}
                                sx={{ '&:hover': { cursor: 'pointer', bgcolor: '#ddd' } }}>
                                <TableCell><Typography>{r.title}</Typography></TableCell>
                                <TableCell><Typography style={{ width: "maxContent" }}>{FormatDT(r.date_time)}</Typography></TableCell>
                                <TableCell><Typography>{r.type}</Typography></TableCell>
                                <TableCell><Typography>{r.link || r.venue}</Typography></TableCell>
                                <TableCell><Typography>{r.description}</Typography></TableCell>
                                <TableCell onClick={(e) => e.stopPropagation()} align='right'>
                                    <IconButton title='Edit meeting' onClick={() => { setrptObj(r); setrptEdit(true); setrptModal(true); setEditDateTime(false) }}><EditNote sx={{ color: '#b5651d' }} /></IconButton>
                                </TableCell>
                            </TableRow>))}</TableBody>

                            <TableFooter><TableRow><TablePagination count={pastF.length}
                                page={page} rowsPerPage={10}
                                sx={{ '.MuiTablePagination-displayedRows': { display: 'none' } }}
                                onPageChange={(e, p) => { setPage(p) }} rowsPerPageOptions={[]}
                                ActionsComponent={TPA} /></TableRow></TableFooter>
                        </Table></TableContainer></Paper>
                        : (ti === '2' && archF.length > 0) ?
                            <Paper sx={{ width: '100%', overflow: 'hidden' }} hidden={PerChk('PAST_MEETINGS')}><TableContainer sx={{ maxHeight: 440 }}><Table>
                                <TableHead sx={adminTH}><TableRow>
                                    <TableCell sx={{ color: '#fff' }}>Title</TableCell>
                                    <TableCell sx={{ color: '#fff' }}>Date & Time</TableCell>
                                    <TableCell sx={{ color: '#fff' }}>Type</TableCell>
                                    <TableCell sx={{ color: '#fff' }}>Link/Venue</TableCell>
                                    <TableCell sx={{ color: '#fff' }}>Description</TableCell>
                                </TableRow></TableHead>

                                <TableBody>{archP.sort((a, b) => new Date(b.date_time) - new Date(a.date_time)).map((r, i) => (<TableRow key={i} onClick={() => { setrptObj(r); setrptModal(true); }}
                                    sx={{ '&:hover': { cursor: 'pointer', bgcolor: '#ddd' } }}>
                                    <TableCell><Typography>{r.title}</Typography></TableCell>
                                    <TableCell><Typography style={{ width: "maxContent" }}>{FormatDT(r.date_time)}</Typography></TableCell>
                                    <TableCell><Typography>{r.type}</Typography></TableCell>
                                    <TableCell><Typography>{r.link || r.venue}</Typography></TableCell>
                                    <TableCell><Typography>{r.description}</Typography></TableCell>
                                </TableRow>))}</TableBody>

                                <TableFooter><TableRow><TablePagination count={pastF.length}
                                    page={page} rowsPerPage={10}
                                    sx={{ '.MuiTablePagination-displayedRows': { display: 'none' } }}
                                    onPageChange={(e, p) => { setPage(p) }} rowsPerPageOptions={[]}
                                    ActionsComponent={TPA} /></TableRow></TableFooter>
                            </Table></TableContainer></Paper>
                            :
                            <Typography variant='h6' sx={{ textAlign: 'center' }}>{searchFilter ? 'No results for your search' : 'No records'}</Typography>
            }</Grid>
        </Grid></Container >

        <Dialog open={archModal}>
            <DialogTitle sx={{ mb: '5px', borderBottom: '1px solid #b5651d' }}><Typography variant='h6'>Confirmation</Typography></DialogTitle>
            <DialogContent><Typography sx={{ mb: '20px' }}>Are you sure you want to cancel this meeting?</Typography></DialogContent>
            <DialogActions style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: '5px' }}>
                <Button sx={pBtn} onClick={() => setarchModal(null)} disabled={isLoading}>No</Button>
                <Button sx={nBtn} onClick={() => ArchReport(archModal)} disabled={isLoading}>{isLoading ? <CircularProgress size={24} /> : 'Yes'}</Button>
            </DialogActions>
        </Dialog>

        <Dialog fullWidth open={addModal}>
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #b5651d', mb: '5px' }}>
                <Typography variant='h6'>Add New Meeting</Typography>
                <Close sx={{ color: '#fff', bgcolor: 'red', borderRadius: '15%', cursor: 'pointer' }} onClick={() => {
                    setaddModal(false); setrptObj(meetObj); setdateTemp(now.toLocaleDateString('en-CA'));
                    settimeTemp(now.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', hour12: false }));
                }} />
            </DialogTitle>

            <DialogContent><Card><Grid container spacing={2}>
                <Grid item xs={isMobile ? 12 : 8}><TextField required label='Title' value={rptObj?.title}
                    onChange={(e) => setrptObj({ ...rptObj, title: e.target.value })} /></Grid>

                <Grid item xs={isMobile ? 12 : 2}><TextField required type='date' label='Meeting Date' onKeyDown={(e) => e.preventDefault()}
                    value={dateTemp} onChange={(e) => setdateTemp(e.target.value)} /></Grid>

                <Grid item xs={isMobile ? 12 : 2}><TextField required type='time' label='Meeting Time' onKeyDown={(e) => e.preventDefault()}
                    value={timeTemp} onChange={(e) => settimeTemp(e.target.value)} /></Grid>

                <Grid item xs={isMobile ? 12 : 3}><TextField required select label='Meeting Type' value={rptObj?.type}
                    onChange={(e) => setrptObj({ ...rptObj, type: e.target.value, link: '', venue: '' })}>
                    <MenuItem value='Online'>Online</MenuItem>
                    <MenuItem value='Face-to-Face'>Face-to-Face</MenuItem>
                </TextField></Grid>

                <Grid item xs={isMobile ? 12 : 9}>{
                    rptObj?.type === 'Online' ? <TextField required label='Link' value={rptObj?.link}
                        onChange={(e) => setrptObj({ ...rptObj, link: e.target.value })} />
                        : rptObj?.type === 'Face-to-Face' ? <TextField required label='Venue' value={rptObj?.venue}
                            onChange={(e) => setrptObj({ ...rptObj, venue: e.target.value })} />
                            : <TextField disabled value={'Select meeting type first'} />
                }</Grid>

                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <FormHelperText>Invite participants</FormHelperText>
                        <Select multiple value={rptObj?.total_participants} onChange={(e) => setrptObj({ ...rptObj, total_participants: e.target.value, attended_participants: [] })}
                            renderValue={(selected) => { const selectedUsers = selected.map((id) => getUserFullNameById(id)); return `${selected.length} selected: ${selectedUsers.join(', ')}`; }} MenuProps={MenuProps}>
                            <ListSubheader>Face-to-Face</ListSubheader>
                            {uList.filter((user) => user.support_via === 'Face-to-Face').map((user) => (
                                <MenuItem key={user._id} value={user.user_id} disabled={checkInactive(user.user_id)}>
                                    <Checkbox checked={rptObj?.total_participants.indexOf(user.user_id) > -1} />
                                    <ListItemText primary={getUserFullNameById(user.user_id)} />
                                </MenuItem>
                            ))}

                            <ListSubheader>Online</ListSubheader>
                            {uList.filter((user) => user.support_via === 'Online').map((user) => (
                                <MenuItem key={user._id} value={user.user_id} disabled={checkInactive(user.user_id)}>
                                    <Checkbox checked={rptObj?.total_participants.indexOf(user.user_id) > -1} />
                                    <ListItemText primary={getUserFullNameById(user.user_id)} />
                                </MenuItem>
                            ))}

                            <ListSubheader>Admins</ListSubheader>
                            {nList.filter((user) => user.roletype_key === 'SAS_ADMIN' || user.roletype_key === 'SUPER_ADMIN').map((user) => (
                                <MenuItem key={user._id} value={user._id} disabled={checkInactive(user._id)}>
                                    <Checkbox checked={rptObj?.total_participants.indexOf(user._id) > -1} />
                                    <ListItemText primary={user.full_name} />
                                </MenuItem>
                            ))}
                        </Select>
                        {rptObj?.total_participants.length < 2 && (<FormHelperText error>Select at least 2 participants</FormHelperText>)}
                    </FormControl>
                </Grid>

                <Grid item xs={12}><TextField required multiline rows={4} label='Description' value={rptObj?.description}
                    helperText={`${rptObj?.description?.split(/\s+/).length || 0}/500`}
                    onChange={(e) => {
                        const wordCount = e.target.value.split(/\s+/).length;
                        if (wordCount <= 500) { setrptObj({ ...rptObj, description: e.target.value }) }
                    }} /></Grid>

                {/*isPast && <>
                    <Grid item xs={12}><Typography variant='h6' sx={{ mb: 0 }}>Add report</Typography></Grid>

                    <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row' }}><Typography>
                        <Info /> Meeting report is also required since meeting has already taken place
                    </Typography></Grid>

                    <Grid item xs={12}><FormControl fullWidth>
                        <FormHelperText>Attendance</FormHelperText>
                        <Select multiple value={rptObj?.attended_participants} onChange={(e) => setrptObj({ ...rptObj, attended_participants: e.target.value })}
                            renderValue={(selected) => {
                                const selectedUsers = selected.map((id) => {
                                    const user = nList.find((user) => user._id === id);
                                    return user ? user.full_name : id;
                                }); return `${selected.length} selected: ${selectedUsers.join(', ')}`;
                            }} MenuProps={MenuProps}>
                            {nList.filter(user => rptObj?.total_participants.includes(user._id)).map((user) => (<MenuItem key={user._id} value={user._id}>
                                <Checkbox checked={rptObj?.attended_participants.indexOf(user._id) > -1} />
                                <ListItemText primary={user.full_name} />
                            </MenuItem>))}
                        </Select>
                    </FormControl></Grid>

                    <Grid item xs={12}><TextField required multiline rows={4} label='Summary' value={rptObj?.summary}
                        helperText={`${rptObj?.summary?.split(/\s+/).length || 0}/500`}
                        onChange={(e) => {
                            const wordCount = e.target.value.split(/\s+/).length;
                            if (wordCount <= 500) { setrptObj({ ...rptObj, summary: e.target.value }) }
                        }} /></Grid>
                </>*/}
            </Grid></Card></DialogContent>

            <DialogActions sx={{ display: 'flex', justifyContent: 'flex-end', gap: '5px' }}>
                <Button sx={pBtn} onClick={AddReport} disabled={addDisabled || isLoading}
                    title='Add report after filling all necessary fields'>
                    {isLoading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>

        <Dialog fullWidth open={rptModal}>
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', mb: '5px', borderBottom: '1px solid #b5651d' }}>
                <Typography variant='h6' sx={{ mb: 0 }}>{rptObj?.title}</Typography>
                <Close sx={{ color: '#fff', bgcolor: 'red', borderRadius: '15%', cursor: 'pointer' }} onClick={() => { setrptModal(false); setrptEdit(false); setrptObj(meetObj); }} />
            </DialogTitle>

            <DialogContent><Card sx={{ gap: '10px' }}>
                <Typography><b>Type</b>: {rptObj?.type}</Typography>

                <Typography>
                    {rptObj?.type === 'Face-to-Face' && <><b>Venue: </b>{rptObj?.venue}</>}
                    {rptObj?.type === 'Online' && <><b>Link: </b><a href={rptObj?.link}>{rptObj?.link}</a></>}
                </Typography>

                <Typography><b>Date & time</b>: {FormatDT(rptObj?.date_time)}</Typography>

                <Typography style={{ display: 'block', wordBreak: 'break-word' }}>
                    <b>Meeting Description: </b>{rptObj?.description}
                </Typography>

                {rptEdit && editDateTime && <>
                    <Grid container spacing={2}>
                        <Grid item xs={isMobile ? 12 : 3}><TextField required type='date' label='Meeting Date' onKeyDown={(e) => e.preventDefault()}
                            value={IsoToDate(rptObj.date_time)} onChange={(e) => setdateTemp(e.target.value)} /></Grid>

                        <Grid item xs={isMobile ? 12 : 3}><TextField required type='time' label='Meeting Time' onKeyDown={(e) => e.preventDefault()}
                            value={IsoToTime(rptObj.date_time)} onChange={(e) => settimeTemp(e.target.value)} /></Grid>
                    </Grid>
                </>}

                {rptEdit ? <>
                    <FormControl fullWidth>
                        <FormHelperText>Invite participants</FormHelperText>
                        <Select multiple value={rptObj?.total_participants} onChange={(e) => setrptObj({ ...rptObj, total_participants: e.target.value, attended_participants: [] })}
                            renderValue={(selected) => { const selectedUsers = selected.map((id) => getUserFullNameById(id)); return `${selected.length} selected: ${selectedUsers.join(', ')}`; }} MenuProps={MenuProps}>
                            <ListSubheader>Face-to-Face</ListSubheader>
                            {uList.filter((user) => user.support_via === 'Face-to-Face').map((user) => (
                                <MenuItem key={user._id} value={user.user_id} disabled={checkInactive(user.user_id)}>
                                    <Checkbox checked={rptObj?.total_participants.indexOf(user.user_id) > -1} />
                                    <ListItemText primary={getUserFullNameById(user.user_id)} />
                                </MenuItem>
                            ))}

                            <ListSubheader>Online</ListSubheader>
                            {uList.filter((user) => user.support_via === 'Online').map((user) => (
                                <MenuItem key={user._id} value={user.user_id} disabled={checkInactive(user.user_id)}>
                                    <Checkbox checked={rptObj?.total_participants.indexOf(user.user_id) > -1} />
                                    <ListItemText primary={getUserFullNameById(user.user_id)} />
                                </MenuItem>
                            ))}

                            <ListSubheader>Admins</ListSubheader>
                            {nList.filter((user) => user.roletype_key === 'SAS_ADMIN' || user.roletype_key === 'SUPER_ADMIN').map((user) => (
                                <MenuItem key={user._id} value={user._id} disabled={checkInactive(user._id)}>
                                    <Checkbox checked={rptObj?.total_participants.indexOf(user._id) > -1} />
                                    <ListItemText primary={user.full_name} />
                                </MenuItem>
                            ))}
                        </Select>
                        {rptObj?.total_participants.length < 2 && (<FormHelperText error>Select at least 2 participants</FormHelperText>)}
                    </FormControl>
                    {ti === '1' && <>
                        <Divider sx={{ my: '5px' }} />

                        <Typography variant='h6' sx={{ mb: '2px' }}>Report</Typography>

                        <FormControl fullWidth>
                            <FormHelperText sx={{ gap: '5px' }}>
                                <b style={{ fontSize: 'large' }}>Attendance</b>
                                <small>{rptObj?.attended_participants.length} of {rptObj?.total_participants.length} invited participants attended. {rptObj?.total_participants.length - rptObj?.attended_participants.length} absent.</small>
                            </FormHelperText>
                            <Select disabled={!rptEdit} multiple value={rptObj?.attended_participants} onChange={(e) => setrptObj({ ...rptObj, attended_participants: e.target.value })}
                                renderValue={(selected) => {
                                    const selectedUsers = selected.map((id) => {
                                        const user = nList.find((user) => user._id === id);
                                        return user ? user.full_name : id;
                                    }); return `${selected.length} selected: ${selectedUsers.join(', ')}`;
                                }}>
                                {nList.filter(user => rptObj?.total_participants.includes(user._id)).map((user) => (<MenuItem key={user._id} value={user._id}>
                                    <Checkbox checked={rptObj?.attended_participants.indexOf(user._id) > -1} />
                                    <ListItemText primary={user.full_name} />
                                </MenuItem>))}
                            </Select>
                        </FormControl>

                        <TextField required disabled={!rptEdit} multiline rows={4} label='Summary' value={rptObj?.summary}
                            helperText={`${rptObj?.summary?.split(/\s+/).length || 0}/500`}
                            onChange={(e) => {
                                const wordCount = e.target.value.split(/\s+/).length;
                                if (wordCount <= 500) { setrptObj({ ...rptObj, summary: e.target.value }) }
                            }} />
                    </>}
                </> : <>
                    <Typography>
                        <b>Participants invited: </b>{nList.filter(user => rptObj?.total_participants.includes(user._id)).map(user => user.full_name).join(', ')}
                    </Typography>

                    {ti === '1' && <>
                        <Divider sx={{ my: '5px' }} />

                        <Typography variant='h6' sx={{ mb: '2px' }}>Report</Typography>

                        <Typography>
                            <b>Participants attended: </b>{nList.filter(user => rptObj?.attended_participants.includes(user._id)).map(user => user.full_name).join(', ')}
                        </Typography>

                        <Typography><b>Report summary: </b>{rptObj?.summary}</Typography>
                    </>}

                    {ti === '2' && <Typography variant='h6' sx={{ color: 'red', m: 0 }}>This meeting has been cancelled.</Typography>}
                </>}
            </Card></DialogContent>
            {rptEdit && <DialogActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={() => EditReport(rptObj?._id)} disabled={ti === '1' ? editRptDisabled || isLoading : editDisabled || isLoading} sx={pBtn}>
                    {isLoading ? <CircularProgress size={24} /> : 'Save'}</Button>
            </DialogActions>}
        </Dialog >
    </>)
}