import React from 'react';
import { Box, IconButton, useTheme, Typography } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

export const formatDateStr = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export const formatDate = (date) => (new Date(date).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }))

export const formatTime = (time) => (new Date(time).toLocaleTimeString('en-GB', { hour: '2-digit', time: '2-digit' }))

export const FormatDT = (dt) => (new Date(dt).toLocaleString('en-GB', { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit' }))

export const IsoToDate = (IsoStr) => {
    const date = new Date(IsoStr); return date.toLocaleDateString("en-CA")
};

export const IsoToTime = (IsoStr) => {
    const date = new Date(IsoStr);
    return date.toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
    })
}

export const PerChk = (per) => !((JSON?.parse(sessionStorage?.getItem('uPer')) || ['Null'])?.map(p => p.trim()))?.includes(per)

export const TPA = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5, display: 'flex', alignItems: 'center' }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <Typography sx={{ mx: 2 }}>
                {page + 1}
            </Typography>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}

export const setTimeoutsecs = 1000;
export const setAutoHideDurationTimeoutsecs = 3000;
export const adminTH = { bgcolor: '#a5644e', position: 'sticky', top: 0, zIndex: 100 }
export const txtElp = { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', wordWrap: 'break-word', }
export const modCard = { display: 'flex', height: '100%', bgcolor: 'white', border: '1px solid gray', '&:hover': { cursor: 'pointer', border: '1px solid #b5651d' } }
export const pBtn = { backgroundColor: '#48BF53', '&:hover': { backgroundColor: '#91F086' } }
export const nBtn = { backgroundColor: '#A70000', '&:hover': { backgroundColor: '#A0153E' } }
export const trClose = { color: '#fff', bgcolor: 'red', borderRadius: '15%', cursor: 'pointer', position: 'absolute', top: 5, right: 5 }